import { Injectable, inject } from '@angular/core';
import { Actions, Store } from '@ngxs/store';
import { fromEvent, tap } from 'rxjs';

import { SetScreen } from './screen.actions';

@Injectable({
  providedIn: 'root',
})
export class ScreenActionHandler {

  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);

  constructor() {
    fromEvent(window, 'resize').pipe(
      tap(() => {
        this.store.dispatch(new SetScreen(window.innerWidth < 1025 ? 'mobile' : 'desktop'));
      })
    ).subscribe();
  }
}
