import { Injectable, inject } from '@angular/core';
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  StateToken,
} from '@ngxs/store';

import { ScreenActionHandler } from './screen.action-handler';
import { SetScreen } from './screen.actions';

export type Screen = 'desktop' | 'mobile';

export const SCREEN_STATE = new StateToken<Screen>('screen');

@State({
  name: SCREEN_STATE,
  defaults: 'desktop',
})
@Injectable()
export class ScreenState implements NgxsOnInit {

  private readonly screenHandler = inject(ScreenActionHandler);

  @Selector() static screen(state: Screen): Screen {
    return state;
  }

  ngxsOnInit({ setState }: StateContext<Screen>): void {
    if (window.innerWidth < 1025) {
      setState('mobile');
    }
  }

  @Action(SetScreen)
  setScreen(
    { setState }: StateContext<Screen>,
    { screen }: SetScreen,
  ) {
    setState(screen);
  }
}
